  const groupText=[
     {field:"black_list",meaning:"黑名单纠错"},
     {field:"pol",meaning:"政治术语纠错"},
     {field:"char",meaning:"别字纠错"},
     {field:"word",meaning:"别词纠错"},
     {field:"redund",meaning:"语法纠错-冗余"},
     {field:"miss",meaning:"语法纠错-缺失"},
     {field:"order",meaning:"语法纠错-乱序"},
     {field:"dapei",meaning:"搭配纠错"},
     {field:"punc",meaning:"标点纠错"},
     {field:"idm",meaning:"成语纠错"},
     {field:"org",meaning:"机构名纠错"},
     {field:"leader",meaning:"领导人职称纠错"},
     {field:"number",meaning:"数字纠错"},
     {field:"addr",meaning:"地名纠错"},
     {field:"name",meaning:"全文人名纠错"},
     {field:"grammar_pc",meaning:"句式杂糅&语义重复"}
  ]

  export default groupText